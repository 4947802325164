<template>
  <flex fill class="height-100" :class="{ loading }">
    <iopr-header class="px-20 py-15 shadow">
      <template #icon>
        <iopr-module-icon :icon="moduleOptions.icon" />
      </template>
      <template #title>
        <span class="px-10">{{ moduleOptions.name }}</span>
      </template>
      <template #action>
        <icon
          class="font-color-gray"
          name="mobile"
          :size="20"
          @click="isOpenMenu = 1"
        />
      </template>
    </iopr-header>
    <flex fill class="overflow-auto">
      <router-view v-if="isDataLoaded" />
    </flex>
  </flex>

  <modal full v-if="isOpenMenu">
    <iopr-header class="px-20 py-15">
      <template #icon>
        <iopr-module-icon :icon="moduleOptions.icon" />
      </template>
      <template #title>
        <span class="px-10">{{ moduleOptions.name }}</span>
      </template>
      <template #action>
        <icon
          class="font-color-gray"
          name="close"
          :size="20"
          @click="$router.back()"
        />
      </template>
    </iopr-header>
    <flex column fill class="bg-gray_l-10 overflow-auto">
      <iopr-navbar :schema="navbarSchema" class="mb-20" />
      <iopr-user class="p-20" />
    </flex>
  </modal>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useDistricts from '@/composition/useDistricts';
import useYears from '@/composition/useYears';

export default {
  props: {
    moduleOptions: {
      type: Object,
      required: true
    },
    navbarSchema: {
      type: Array,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isOpenMenu = computed({
      get() {
        return Boolean(route.query.menu);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            menu: value
          }
        });
      }
    });

    const { load: loadDistricts, loading: loadingDistricts } = useDistricts();
    const { load: loadYears, loading: loadingYears } = useYears();
    const loading = computed(
      () => loadingDistricts.value || loadingYears.value
    );
    const isDataLoaded = ref(false);
    onMounted(async () => {
      try {
        await Promise.all([loadDistricts(), loadYears()]);
        isDataLoaded.value = true;
      } catch (error) {
        alert(error.message);
      }
    });

    return {
      isDataLoaded,
      isOpenMenu,
      loading
    };
  }
};
</script>
